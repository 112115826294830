import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const EtchoIndex = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="work">
        <div className="work__header">
          <div>
            <h1>Etcho</h1>
            <p className="work__strap">React Native, NestJS &amp; RemixJS</p>
          </div>
          <div className="work__branding">
            <StaticImage
              src="../../images/etcho/logo.png"
              alt="Etcho Logo"
              width={100}
              height={100}
            />
          </div>
        </div>
      </div>

      <h2>Overview</h2>

      <p>
        <a href="https://www.etcho.io">Etcho</a> is a mobile app to allow users
        to learn the impact of your investments and discover companies matching
        your morals.
      </p>

      <h3>✨ Noteworthy</h3>

      <ul>
        <li>User authentication with Auth0</li>
        <li>NestJS API</li>
        <li>
          Consolidating timely stock and environmental data from 3 large
          datasets into simple visualisations
        </li>
        <li>RemixJS web application consuming the same API</li>
      </ul>

      <h2>💪 Responsibilities</h2>

      <p>
        I was approached by the Etcho tech lead who was looking for an
        experienced developer who could hit the ground running, improve the
        existing codebase and deliver on the tight delivery timeframe Etcho were
        keen to meet.
      </p>
      <ul>
        <li>
          Working autonomously on the React Native, NestJS and Remix.run
          codebase to deliver on requirements.
        </li>
        <li>
          Establishing good unit and e2e testing strategies across all 3 code
          bases (React Native, NestJS, Remix Run) to ensure we can build quickly
          and reliably.
        </li>
        <li>
          Researched and delivered the technology stack (RemixJS) for the web
          platform MVP in 4 weeks.
        </li>
      </ul>
      <h2>👀 At a glance</h2>
      <ul className="work__at-a-glance">
        <li>Typescript</li>
        <li>React Native</li>
        <li>Remix.run</li>
        <li>NestJS</li>
        <li>Jest & Vitetest</li>
        <li>Cypress</li>
        <li>Terraform</li>
        <li>Docker</li>
        <li>BDD</li>
        <li>TDD</li>
        <li> AWS</li>.
      </ul>
      <h2>🚶 Walkthrough</h2>

      <h2>🚀 Status</h2>

      <ul>
        <li>
          Application available in{" "}
          <a href="https://apps.apple.com/gb/app/etcho/id1619782104">
            Apple App Store
          </a>{" "}
          and{" "}
          <a href="https://play.google.com/store/apps/details?id=io.etcho.etcho">
            Google Play
          </a>
        </li>
        <li>MVP web platform developed and shipped in 1 month</li>
      </ul>
    </Layout>
  )
}

export default EtchoIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Etcho" noIndex={true} />
